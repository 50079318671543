exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-browse-more-js": () => import("./../../../src/pages/work/browseMore.js" /* webpackChunkName: "component---src-pages-work-browse-more-js" */),
  "component---src-pages-work-chicory-js": () => import("./../../../src/pages/work/chicory.js" /* webpackChunkName: "component---src-pages-work-chicory-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-lawline-app-js": () => import("./../../../src/pages/work/lawline-app.js" /* webpackChunkName: "component---src-pages-work-lawline-app-js" */),
  "component---src-pages-work-lawline-js": () => import("./../../../src/pages/work/lawline.js" /* webpackChunkName: "component---src-pages-work-lawline-js" */),
  "component---src-pages-work-sourced-adventures-js": () => import("./../../../src/pages/work/sourced-adventures.js" /* webpackChunkName: "component---src-pages-work-sourced-adventures-js" */),
  "component---src-pages-work-transaccts-js": () => import("./../../../src/pages/work/transaccts.js" /* webpackChunkName: "component---src-pages-work-transaccts-js" */)
}

